@media (max-width: 767px) {
.navbar{
    background-color: #ebdafc;
    box-shadow: 0px 0px 6px #c4a8e3;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
}