:root {
    --primary-color: #AC66F2;
    --secondary-color: #F2E5FF;
    --font-color: #0A0240;
    --white-color: #ffffff;
    --black-color: #000000;
    --font14: 14px;
    --font16: 16px;
    --font18: 18px;
    --font20: 20px;
    --font22: 22px;
    --font32: 32px;
    --font-gilory: 'Gilroy-Regular';
}

body {
    font-family: var(--font-gilory);
    font-size: var(--font14);
    font-weight: var(--weight-400);
    color: var(--black-color);
    background-image: url(./assests/Body_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}