 /* faq */
  
 .title {
    font-family: 'Gilroy-Bold';
    font-size: var(--font32);
    line-height: 52px;
    color: var(--font-color);
  }
  
  .accordion-button::after {
    background: var(--primary-color);
    background-image: url("../../assests/plus.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    transform: unset;
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
  
  .accordion-button:not(.collapsed)::after {
    background: var(--secondary-color);
    background-image: url("../../assests/minus.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    width: 40px;
    height: 40px;
    transform: unset;
    border-radius: 5px;
  }
  
  .accordion-button {
    font-family: 'Gilroy-Semibold';
    font-size: var(--font22);
    line-height: 29px;
    color: var(--font-color);
    background-color: var(--secondary-color);
    transition: all .1s linear;

  }
  
  .accordion-button:not(.collapsed) {
    color: var(--white-color);
    background-color: var(--primary-color);
    box-shadow: unset;
  }
  
  .accordion-button:focus,
  .accordion-button:focus-visible {
    box-shadow: unset;
    outline: none;
  }
  
  
  .accordion-body p {
    font-weight: var(--weight-400);
    font-size: var(--font16);
    line-height: 21px;
    color: var(--black-color);
    margin-bottom: 0;
   
    
  }
  
  .accordion-item {
    background-color: transparent;
    border: 0;
  }
  
  .accordion-body {
    padding: 15px 0;
  }
  
  .accordion-header {
    border-radius: 10px;
  }
  
  .accordion-item .accordion-button {
    border-radius: 10px !important;
    padding: 15px 20px;
  }
  .accordion-collapse{
    opacity: 0;
    
  }
  .collapse.show{
    opacity: 1;
    transition: all 0.1s linear;
   
  }
@media (max-width: 1200px) {
  
  .title {
    font-size: var(--font32);
    line-height: 42px;
}
.accordion-body p {
    font-size: var(--font14);
}
.accordion-button {
    font-size: var(--font20);
    line-height: 24px;
}
.accordion-button::after {
    width: 32px;
    height: 32px;
}
.accordion-button:not(.collapsed)::after {
    width: 32px;
    height: 32px;
}
}

@media (max-width: 991px) {
    /* faq */
  .title {
    font-size: var(--font20);
    line-height: 24px;
}
.accordion-body p {
    font-size: var(--font14);
}
.accordion-button {
    font-size: var(--font14);
    line-height: 18px;
}
.accordion-button::after {
    width: 20px;
    height: 20px;
}
.accordion-button:not(.collapsed)::after {
    width: 20px;
    height: 20px;
}
.accordion-item .accordion-button {
    padding: 15px 18px 15px 15px;
}
}