.sync{
    border-left: 2px solid #AC66F2;
    position: relative;
    padding: 0 1.5rem 0.5rem 0rem;
    height: 145px;
}
.sync:last-child{
    border-left: 4px solid transparent;
}
.zksync-icon{
    position: absolute;
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    left:-22px;
    top:0px;
}
.zksync-icon img{
    width: 50%;
    align-items: center;
    
}
.zksync-desc{
    padding-left: 30px;
}
.right-part img{
    width: -webkit-fill-available;
}
.zksync-desc h5{
    font-size: var(--font32);
    font-family: 'Gilroy-Bold';
}
.zksync-desc h6{
    font-size: var(--font22);
    font-family: 'Gilroy-Bold';
}
.mint-btn{
    background-color: var(--primary-color);
    border-radius: 70px;
    border: var(--primary-color);
    box-shadow: 0px 4px 0 0px #8C21F7;
    padding: 8px 15px;
}
.mint-btn:hover, .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    background-color: #9254d0;
}

.eth_logo{
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 3px;
}
.story-line_story-line--center__kW06K {
    width: calc(96%);
    transform: translateX(4px) translateY(2px);
   
    height: 27.5vw;
    margin-top: -6px;
    margin-left: -4px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1110px;
    }
}

@media (max-width: 767px) {
    .story-line_story-line--center__kW06K{
        width: calc(50%);
    }
    .order-first {
        order: -1!important;
    }
    .right-part{
        justify-content: center;
        display: flex;
    }
    .right-part img {
        max-width: 374px;
        width: 100%;
    }
}
