
.footer-section p {
    padding: 35px 15px;
    background: var(--primary-color);
    color: var(--white-color);
    border-radius: 10px;
    font-weight: var(--weight-500);
    font-size: var(--font22);
    line-height: 31px;
  }
  
  .footer-section span svg {
    width: 24px;
    height: 24px;
    fill: #47CD2D;
    display: inline-block;
    vertical-align: initial;
  }
  
  .footer-section svg path {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

  @media (max-width: 1200px) {
   
  .footer-section p {
    padding: 25px 15px;
    line-height: 28px;
}
.footer-section span svg {
    width: 20px;
    height: 20px;
}
}
  @media (max-width: 991px) {

  .footer-section p {
    padding: 20px 15px;
    font-size: var(--font14);
    line-height: 18px;
}
.footer-section span svg {
    width: 14px;
    height: 14px;
}
  }