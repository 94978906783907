@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy Regular'), url('./fonts/Gilroy-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy Bold'), url('./fonts/Gilroy-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy Medium'), url('./fonts/Gilroy-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Gilroy-Semibold';
  src: local('Gilroy Semibold'), url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: 'Gilroy-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
